/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import React from "react";
import { rem } from "@src/theme";
import ArticleListingItem from "@components/ArticleListingItem";
import ContributorListingItem from "@components/ContributorListingItem";
import { Grid, Cell } from "@components/primitives/Grid";
import { Link } from "gatsby";
import pluralize from "pluralize";

interface ComponentProps {
  items: Array<any>;
  featureTag?: {
    title: string;
    slug: string;
  };
  contributorMode?: boolean;
}

const ArticleListingGrid = ({
  featureTag,
  items,
  contributorMode,
}: ComponentProps) => (
  <React.Fragment>
    {featureTag && (
      <React.Fragment>
        <hr
          sx={{
            bg: "coal",
            height: "1px",
            border: "none",
          }}
        />
        <Styled.h3
          sx={{
            mt: rem(16),
          }}
        >
          Featured{" "}
          <Link
            to={`/tags/${featureTag.slug}`}
            sx={{
              color: "inherit",
            }}
          >
            {pluralize(featureTag.title.toLowerCase())}
          </Link>
        </Styled.h3>
      </React.Fragment>
    )}

    <Grid
      gridTemplateColumns={["repeat(15, 1fr)"]}
      gridRowGap={[rem(16), null, rem(96)]}
    >
      {items.map((i) => (
        <Cell gridColumn={["1 / -1", "span 5"]}>
          {contributorMode ? (
            <ContributorListingItem {...i} />
          ) : (
            <ArticleListingItem gridItem={true} {...i} />
          )}
        </Cell>
      ))}
    </Grid>
  </React.Fragment>
);

export default ArticleListingGrid;
