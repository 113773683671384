import { rem } from "@src/theme";

export default {
  container: (gridItem) => ({
    m: 0,
    display: gridItem ? ["flex"] : ["block", "flex"],
    flexDirection: gridItem ? ["row", "column", null] : ["initial"],
    height: "100%",
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      mb: "2rem",
    },
  }),
  caption: (gridItem) => ({
    flex: gridItem ? [1, 0, null] : 1,
    display: gridItem ? "flex" : "flex",
    flexDirection: "column",
    pl: gridItem ? ["20px", 0] : [0, "18px", "24px", null, "48px"],
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      flex: gridItem ? ["1 1 auto", "0 0 auto", null] : "1 1 auto",
    },
  }),
  captionHeading: {
    mt: 0,
    mb: 0,
    lineHeight: 1.15,
    letterSpacing: ["-0.5px", null, "-1px"],

    fontFamily: "heading",
    color: "char",
  },
  captionText: (gridItem) => ({
    variant: "typography.bodyMedium",
    display: gridItem ? ["none", "block"] : "block",
    color: "black",
    mb: rem(0),
  }),
};
