/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import styles from "./styles";
import { Link } from "gatsby";
import ReadMore from "@components/ReadMore";
import pluralize from "pluralize";
import { trimSentence } from "@src/helpers/utils";

interface ComponentProps {
  title: string;
  url: string;
  img: string;
  introText: string;
  lgHeading: Boolean;
}
const ContributorListingItem = ({
  title,
  img,
  url,
  introText,
  lgHeading,
}: ComponentProps) => {
  const MAX_LENGTH = 100;
  const preview = introText ? trimSentence(introText, MAX_LENGTH) : "";
  const contributorName = title.split(" ")[0] || "";
  return (
    <Link
      to={url}
      sx={{
        textDecoration: "none",
        color: "inherit",
        height: "100%",
        display: "block",
        "&:hover": {
          bg: "rgba(0, 0, 0, 0.08)",
          outline: "10px solid rgba(0,0,0,0.08)",
          transform: "translateY(-3px)",
        },
      }}
    >
      <figure sx={styles.container(true)}>
        {img ? (
          <div
            sx={{
              minHeight: [rem(160), rem(160), rem(220)],
              maxHeight: [rem(160), rem(160), rem(220)],
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              flex: 1,
            }}
          ></div>
        ) : (
          <div
            sx={{
              bg: "smoke",
              minHeight: rem(120),
              flex: 1,
            }}
          ></div>
        )}

        <figcaption sx={styles.caption(true)}>
          <div>
            <div
              sx={{
                mt: [0, rem(15)],
                mb: rem(5.6),
              }}
            ></div>

            <h2
              sx={{
                ...styles.captionHeading,
                fontSize: lgHeading
                  ? "1.91em"
                  : ["1.55em", null, "1.55em", "1.45em"],
              }}
            >
              {title}
            </h2>
          </div>

          <p sx={styles.captionText(true)}>
            <span dangerouslySetInnerHTML={{ __html: preview }}></span>
            <ReadMore
              url={url}
              label={`See ${pluralize(contributorName)} articles`}
            />
          </p>
        </figcaption>
      </figure>
    </Link>
  );
};

export default ContributorListingItem;
