/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import styles from "./styles";
import TagDuo from "@components/TagDuo";
import { Link } from "gatsby";
import ReadMore from "@components/ReadMore";
import { trimSentence } from "@src/helpers/utils";

interface ComponentProps {
  gridItem?: Boolean;
  title: string;
  tagDuo: Array<string>;
  url: string;
  img: string;
  introText: string;
  lgHeading: Boolean;
}
const ArticleListingItem = ({
  gridItem = false,
  title,
  tagDuo,
  img,
  url,
  introText,
  lgHeading,
}: ComponentProps) => {
  const MAX_LENGTH = gridItem ? 100 : 94;
  const preview = introText ? trimSentence(introText, MAX_LENGTH) : "";
  return (
    <Link
      to={url}
      sx={{
        textDecoration: "none",
        color: "inherit",
        height: "100%",
        display: "block",
        "&:hover": {
          bg: "rgba(0, 0, 0, 0.08)",
          outline: "10px solid rgba(0,0,0,0.08)",
          transform: "translateY(-3px)",
        },
      }}
    >
      <figure sx={styles.container(gridItem)}>
        {img ? (
          <div
            sx={{
              minHeight: [gridItem ? rem(160) : rem(200), rem(160), rem(220)],
              maxHeight: [gridItem ? rem(160) : rem(200), rem(160), rem(220)],
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              flex: 1,
            }}
          ></div>
        ) : (
          <div
            sx={{
              bg: "smoke",
              minHeight: [gridItem ? rem(120) : rem(200), rem(200), rem(220)],
              flex: 1,
            }}
          ></div>
        )}

        <figcaption sx={styles.caption(gridItem)}>
          <div>
            <div
              sx={{
                mt: gridItem ? [0, rem(15)] : [rem(15), 0, null],
                mb: rem(5.6),
              }}
            >
              <TagDuo items={tagDuo} hideType={gridItem} />
            </div>

            <h2
              sx={{
                ...styles.captionHeading,
                fontSize: lgHeading
                  ? "1.91em"
                  : ["1.55em", null, "1.55em", "1.45em"],
              }}
            >
              {title}
            </h2>
          </div>

          <p sx={styles.captionText(gridItem)}>
            <span dangerouslySetInnerHTML={{ __html: preview }}></span>
            <ReadMore url={url} />
          </p>
        </figcaption>
      </figure>
    </Link>
  );
};

export default ArticleListingItem;
